












































































































































































































import orderBy from 'lodash/orderBy'
import {Component, Vue, Prop} from 'vue-property-decorator'
import {mixin as clickaway} from '@/plugins/vue-clickaway.ts'

import ProfileMenu from '@/components/ProfileMenu.vue'
import InputDate from '@/components/inputs/Date.vue'
import InputText from '@/components/inputs/Text.vue'
import DefaultModal from '@/components/modals/Default.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ActionButtons from '@/components/buttons/ActionButtons.vue'

import {ProcessResource} from '@/models/process/ProcessResource'
import {ProjectResource} from '@/models/projects/ProjectResource'

import {ProjectUpdateNameRequest} from '@/requests/projects/ProjectUpdateNameRequest'
import {ProjectUpdateEndDateRequest} from '@/requests/projects/ProjectUpdateEndDateRequest'

import TextInput from '@/components/inputs/Text.vue'
import DateInput from '@/components/inputs/Date.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import TextareaInput from '@/components/inputs/Textarea.vue'

@Component({
  mixins: [clickaway],
  components: {
    InputText,
    TextInput,
    InputDate,
    DateInput,
    SwitchInput,
    SmallLoader,
    ProfileMenu,
    DefaultModal,
    ActionButtons,
    DropdownInput,
    TextareaInput
  }
})
export default class ProjectNavigation extends Vue {
  @Prop()
  private readonly project!: ProjectResource

  private menuOpen: boolean = false
  private projectInfoOpen: boolean = false

  private confirmArchiveModal: boolean = false
  private archiving: boolean = false

  private changeDateModal: { open: boolean, loading: boolean, form: ProjectUpdateEndDateRequest, errors: ErrorResponse } = {
    open: false,
    loading: false,
    form: new ProjectUpdateEndDateRequest(this.project),
    errors: {}
  }

  private changeNameModal: { open: boolean, loading: boolean, form: ProjectUpdateNameRequest, errors: ErrorResponse } = {
    open: false,
    loading: false,
    form: new ProjectUpdateNameRequest(this.project),
    errors: {}
  }

  private metaModal: { isModalOpen: boolean, isSubmitting: boolean, errors: ErrorResponse } = {
    isModalOpen: false,
    isSubmitting: false,
    errors: {}
  }

  private async submitMetaForm(): Promise<void> {
    if (!this.project) return

    this.metaModal.isSubmitting = true
    this.$set(this.metaModal, 'errors', {})
    try {
      await this.project.patchMeta()
      this.metaModal.isModalOpen = false
    } catch ({errors}) {
      if (errors) {
        this.$set(this.metaModal, 'errors', errors)
      }
    } finally {
      this.metaModal.isSubmitting = false
    }
  }

  private openMetaModal(): void {
    this.metaModal.isModalOpen = true
  }

  private closeMetaModal(): void {
    this.metaModal.isModalOpen = false
    this.project.resetMetaValues()
  }

  private get processes(): ProcessResource[] {
    return this.project ? this.project.processes : []
  }

  private get orderedProcesses(): ProcessResource[] {
    if (this.processes.length > 0) {
      return orderBy(this.processes, 'order')
    }

    return []
  }

  private get currentProcessId(): number {
    return +this.$route.params.process_id
  }

  private get isProcessPage(): boolean {
    return (!!this.$route.params.process_id && !this.$route.name?.includes('flexible-document')) ||
        this.$route.name === 'projects-single-detail-connections'
  }

  private get canEditProject(): boolean {
    return this.project.canPerformAction('can_edit_project')
  }

  private getComponent(type: string): string {
    switch (type) {
      case 'dropdown':
      case 'Dropdown':
        return 'DropdownInput'
      case 'date':
        return 'DateInput'
      case 'wysiwyg':
        return 'TextareaInput'
      default:
        return 'TextInput'
    }
  }

  private toggleMenu(): void {
    this.menuOpen = !this.menuOpen
  }

  private closeMenu(): void {
    this.menuOpen = false
  }

  private readNotifications(): void {
    this.project.notifications.readAllNotifications()
  }

  private toggleProjectInfoMenu(): void {
    this.projectInfoOpen = !this.projectInfoOpen
  }

  private toggleChangeDateModal(open: boolean): void {
    this.changeDateModal.open = open
  }

  private toggleChangeNameModal(open: boolean): void {
    this.changeNameModal.open = open
  }

  private async patchProjectName(): Promise<void> {
    this.changeNameModal.loading = true
    try {
      const data = await this.project.patch(this.changeNameModal.form)
      this.changeNameModal.form = new ProjectUpdateNameRequest(data)
    } catch ({errors}) {
      if (errors) {
        this.$set(this.changeNameModal, 'errors', errors)
      }
    } finally {
      this.changeNameModal.loading = false
      this.toggleChangeNameModal(false)
    }
  }

  private async patchProjectDate(): Promise<void> {
    this.changeDateModal.loading = true
    try {
      const data = await this.project.patch(this.changeDateModal.form)
      this.changeDateModal.form = new ProjectUpdateEndDateRequest(data)
    } catch ({errors}) {
      if (errors) {
        this.$set(this.changeDateModal, 'errors', errors)
      }
    } finally {
      this.changeDateModal.loading = false
      this.toggleChangeDateModal(false)
    }
  }

  private getFieldValue(field: string[] | string): string {
    return Array.isArray(field) ? field.join(', ') : field
  }

  private confirmArchive(): void {
    this.confirmArchiveModal = true
  }

  private async archive(): Promise<void> {
    this.archiving = true
    try {
      await this.project.archive()
      await this.$router.push({name: 'projects-overview'})
    } finally {
      this.archiving = false
    }
  }
}
